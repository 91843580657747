import { useContext, useEffect, useState } from "react"
import { Sidebar, Header } from "./index"
import { Appstate } from "../App"
import { message } from "antd";
import { TailSpin } from "react-loader-spinner";
import { SocialIcon } from "react-social-icons";
import { ethers } from "ethers";
import moment from "moment";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "./firebase";
import TransferHistory from "./TransferHistory";
// import WithdrawalHistory from "./WithdrawalHistory";

const Wallet = () => {
  const useAppState = useContext(Appstate);
  const [data, setData] = useState({
    ROI: 0,
    directIncome: 0,
    royaltyIncome: 0,
    uplineIncome: 0,
    downlineIncome: 0,
    generationIncome: 0,
    totalIncome: 0,
    amtInRTR: 0,
    monthlyIncome: 0,
    vUsdt: 0,
    curDayWithdraw: 0,
    isNew: false
  })
  const [data2, setData2] = useState({
    ROI: 0,
    directIncome: 0,
    royaltyIncome: 0,
    uplineIncome: 0,
    downlineIncome: 0,
    generationIncome: 0,
    totalIncome: 0,
    monthlyIncome: 0
  })
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [amount, setAmount] = useState("");
  const [to, setTo] = useState("");

  const transfer = async () => {
    setLoading2(true);
    try {
      let tx = await useAppState.contract.transfer(to, ethers.utils.parseUnits(amount.toString(), "mwei"));
      await tx.wait();

      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Transferred");
    } catch(error) {
      message.error(error.reason)
    }
    setLoading2(false);
  }

  const withdraw = async () => {
    setLoading(true);
    
    let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
    let _userDeposit = useAppState.convert((_user.package));
    let _userDirectBusiness = useAppState.convert(_user.directBusiness);

    let userAddr = useAppState.walletAddress.toLowerCase();
    let _userDoc = await getDoc(doc(db, "rector", userAddr));

    if(_userDoc.exists()) {
      let _newDep = Number(_userDoc.data()['deposit']);
      let _newBusiness = Number(_userDoc.data()['directBusiness']);
      let _prevDep = Number(_userDeposit) - _newDep;

      if(_prevDep <= 7650) {
        if(_newDep >= (_prevDep * 50)/100) {
          await _withdraw();
        } else {
          message.warning(`Increase your deposit by 50% to get withdrawl`)
        }
      } else {
        if(_newBusiness >= (_prevDep * 40)/100) {
          await _withdraw();
        } else {
          message.warning(`Draw a new direct Business of 40% of your total deposit to get withdrawl`)
        }
      }
    } else {
      if(Number(_userDeposit) <= 7650) {
        message.warning(`Increase your deposit by 50% to get withdrawl`)
      } else {
        message.warning(`Draw a new direct Business of 40% of your total deposit to get withdrawl`)
      }
    }

    setLoading(false);
  }

  const _withdraw = async () => {
    setLoading(true);
    try {
      let arr = [
        // "0x4E473104bB979AF239B47DEE5e9F08505bFE4edC",
        // "0xf5d5da4c9568690a732b578fbcca73ed3bf7033e"
      ];

      let isExcluded = true;

      for(let i=0; i<arr.length; i++) {
        if(arr[i].toLowerCase() == useAppState.walletAddress.toLowerCase()) {
          isExcluded = false;
        }
      }

      if(isExcluded) {
        let tx = await useAppState.contract.withdraw();
        await tx.wait();

        useAppState.setChange(useAppState.change + 1);
        message.success("Sucessfully Withdrawn");
      } else {
        message.error("Unauth")
      }

    } catch(error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  useEffect(() => {
    async function req() {
      let _u = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _user = await useAppState.contract.incomeInfo(useAppState.walletAddress);
      // let _amt = 0
      let  _amt = await useAppState.contract.getQuoteInRTR(_user.totalIncome);
      // let  _amt = await useAppState.contract.getQuoteInRTR(ethers.utils.parseUnits("5200", "mwei"));

      let _curDay = await useAppState.contract.getUserCurDay(useAppState.walletAddress);
      let _dayWithdraw = await useAppState.contract.userDayWithdrawal(useAppState.walletAddress, _curDay);

      let arr = [
        "0x36BCBfDe04ce5ea8f6489B15B1D6500Ff8aD8F65",
        "0x471fa55BBEE311a624E7fDf5809f47B2c14DdcE9",
        "0x4E473104bB979AF239B47DEE5e9F08505bFE4edC",
        "0xf5d5DA4C9568690A732B578fbccA73eD3bF7033e",
        "0x2177272de07dB9B8C16DC6ab216A2492Fe121313",
        "0x120DC3412b1B4a83fe2c21DB5eCD8E1D3cA02A84",
        "0x56Ff272606d572F5fF87EED45Fe434eb7A344Bff",
        "0x3b0c8be5443ce0395544b07a7a627388066c8105",
        "0x2177272de07dB9B8C16DC6ab216A2492Fe121313",
        "0xc255b475600AB8eCeCbd1d44b8c5D83AA5f31D9E",
        "0xaBd74dbC5bd685c831866b31E0Ce58575fF12Ddd",
        "0x8e7C0A70Ad8154A42035eD806222F775FDA4f279",
        "0x3800E3C02AE2F319B1C50A52E96e655b108D8bD9",
        "0x15E59171a2bd231CE3B5D0b42Cc07e3BcF8fE3F7",
        "0x3caE8EdC3a4e2cADA618770F42d578c520fF24f8",
        "0x483Cb5541675888f6325dD9F2F9DeFD1db9a23c6",
        "0x156FAf73852fE8B3B5B72b3823D881B4D9095354",
        "0xC7625E412da841c05c8036c45DFB2D555A934627",
        "0x4203a3649e11EDf733a5078ECB1b0ADc98a6f37F",
        "0xBC458278E16824124cF7c1845D7AFF1803ED349f",
        "0xDF7e4C971c34a48a935b2ac20EC0fBEd14e1c973",
        "0x0C2d3bDA6d9F7f1601b3561c90a45bd17b82B94B",
        "0x088186D70D7CB35aDFD7413AFD8AC211F896f401",
        "0xA1035C8a24AD5F9f4B40F25Cb3263A0230A41178",
        "0xfB58ff9A137265a6AdD8e412c8a232471b4061d2",
        "0x4F971D9EebC1a611a487553950E44732f3C64946",
        "0x3254eCda77624D0C03451b672E5a557c08d89AdB",
        "0x2C7186e5f1Cfc00238BeF0cE9E7c1d2108404151",
        "0xee4727855043171D516ec32dDeEFd79Aad3AE0c8",
        "0x9dc140aEA3410b7e49d89798B85ef975e0AcC113",
        "0xAAf3d2C7c6bB585c7C3C4d22d275CB8f3F95497f",
        "0x0072C53F08D71B9941D095E11e9FC5Ed95ef0246",
        "0xd9F915e6984a0cB9dDF3582334642db2180777D1",
        "0xd9fba124C157262D833d7E252200eF1487E30815",
        "0x7006a98F3a62a70fe29e5E3Dfe9661C692ade6A4",
        "0xbDEC952F4DB1009391cab4DCAbC9eb87cE25c146",
        "0xad2C693954faB3aC4Cee9B8f02e55D54BF9093B2",
        "0xbD8D748ae68F7Dd95A1471b301E1Ba82E698D09e",
        "0x1C0aBE928387215dA48883C7CDC5597ECeAC764e",
        "0x25cB2D6971C8FA528e2A636C7e63D87dBa9c2D90",
        "0x795F00278f863Cd320F1C2b252f1F7675A1193a9",
        "0xd8C56B24EEaAA952d62C874e3c752260d686885D",
        "0x9ec66F9d5676b0B48982134311b2032C07e510A6",
        "0x4b71C81920c25E6AEd28Fd6496573Ac3135b643D",
        "0x6b5b7Ec03920CF7684b17bFeC986c97f70071207",
        "0x943066116716Eb68454bF71c14f29c7626c4E86b",
        "0x108770fd7e291b2ddbd358834420491c4fa16a39",
        "0x4E473104bB979AF239B47DEE5e9F08505bFE4edC",
        "0x75186A987c7c6138072733F46B8D01eea53f807A",
        "0x471fa55BBEE311a624E7fDf5809f47B2c14DdcE9",
        "0x75186A987c7c6138072733F46B8D01eea53f807A",
        "0xCd3DD6BFe3235af4E7C2ef36055e2849FbFB60ED",
        "0xa7E63888966d1DCbE8B5C135594aC9914070D2b9",
        "0x77C5f9F43A7905cDb476Ec46B0966c43eE90e8d7",
        "0xE3A5F8845acf95bf591132919cd142453b1B2395",
        "0xf67fe37a2b0f23f8eafc85933301eed9d489e3a5",
        "0x3b0c8be5443ce0395544b07a7a627388066c8105",
        "0x1caca11d9849d4eed47e6188ab0495461b431c19",
        "0xD02648c510582dc09D04cAaD507f4Bf3Bfd2bAbB",
        "0x2184CF960033238Bc7d1c30f881838baEf84E820",
        "0x2184CF960033238Bc7d1c30f881838baEf84E820",
        "0xd32C5C749d8CFA8294160006CD6E57d1C76df06E",
        "0xcef1242D603A6D6F305DB06051acee5b7951153b",
        "0x5c38816649330E4D4d17fb55946AB19F12806e4f",
        "0xe24792C097E5d47D0794618F0f73E4a1e4FdA08D",
        "0x07D03d2543f190c59A533D0944D8DC78C7fb4e36",
        "0x7d67a00c59Cc719D95B03e109A05eAfC9fFD4140",
        "0xE3A5F8845acf95bf591132919cd142453b1B2395",
        "0xF2C3577F98d034A2dC020beE8608E482ceAA9b69",
        "0x313C3B22931adC48d8D5c4aAc2A302863DAca4C4",
        "0xE148C14e858a53680F0c1dA3768733b934158E7B",
        "0x843A0311502B29afAE11C4389E77bF156b795716",
        "0x765c51232a76a5B5C1AF3dF209e96A83D0454390",
        "0xA1402a479E7ABf7787FC9eFcA1387Df52784cB65",
        "0x2d2E1c5e1079C2aD7aA1e9f8aC4f54Bc82402Ab1",
        "0xe8B7Fc49b775401C45Acd627DBdedFD794252070",
        "0xE9a2bDF8Ebf0dd997c073Eb6Ca83EC1Dd28C67Ea",
        "0xaf9Dd3d9e04ee50795b4C9FF71b24192BA345beA",
        "0xBeD1AECdDF461c34AC54329814E07995F1e54fc1",
        "0x358eEEF18A0d563Bf09eBd07C76271d93219927a",
        "0x822F63368EBFF8d125247d1886a1614Dc94C1796",
        "0xeE6939d977DD74E96f36B0F4103315206cB373a2",
        "0xBB59F7145CF5E746B094F869A69B75A284e4FcAD",
        "0x9d42AD488D90fB29e9Cc0D86f3736C327471dB8C",
        "0xD02648c510582dc09D04cAaD507f4Bf3Bfd2bAbB",
        "0x095cC616F850FA2c5A5ae32F97cFD959018d2931",
        "0x1be0E78d248688Eb4296990524acf68a3E2c1aCC",
        "0xC34300A8c111bEbe02DCd040f16EF871B7bdC582",
        "0x1caca11d9849d4eed47e6188ab0495461b431c19",
        "0x0cdfba4f74fee722cf339138380c0453e808a349",
        "0x3b0c8be5443ce0395544b07a7a627388066c8105",
        "0xd7ff122f422015b583606f143ae23c2dd17113be",
        "0xfa847ffcf87181fd9326e45c4f745146db3d52b3",
        "0x13f1af6bfb373e2e0bae5dbb5bf56f9240f0176b",
        "0x04CB3E8FD781d4B4197B724D5cEF33aDE48009F6",
        "0x60e48669d0AFd6977c260fCab2443D802A4dC4e5",
        "0xf18442850BFdA69CCA9DA915231aC230b35BfD8a",
        "0xAa60f1e568a926Dfd9C253Cb9d6e28A02F722C8E",
        "0x3F00658B93b9E008482071c59f3266e3d55570A0",
        "0x91E2B81A56df64bF69cf734C56e83251daAB8775",
        "0x0bCC0939BE2957BF2cf972776C9EBBc8fc54C3EA",
        "0x46B5EaE22B70f7E56043B8cB7c226A9604E00733",
        "0x496cb870B6BAF0811c36B12ac32EDB306D1cbbfb",
        "0xD2D7534D61520D473799A9Df56653fAb2507723D",
        "0xc2df375fd5ea5d267a92ba26d3e07f7db0c6e45b",
        "0x21043923d380DFb7f2a690Af451Bf2c146b9E05B",
        "0x0863AAdAA1d818C9BFac5a10eAc5A323969E6c98",
        "0x4641E0bD51Eae0C79ebB5AbC815c47D40f9142E7",
        "0xE9DBa8eDe5Ec710441b87c669e50f1D6A705aC7a",
        "0x76F1Ca7873dFaaC29c9b15607EAcE6a3805Aac02",
        "0x170Fe29C9CA14429266C809f8559230cC5Da3547",
        "0x140c83eba85a4a95ffdf8c7f492a5931151326e4",
        "0x0BF56394AF3F4dB5d28630bA1635650be47F6517",
        "0x90cad5dEb9F97d5D18478b97d5393963c16A95A7",
        "0xB0e94546F7f3440E0287Cc8CaBC5ADF381D02E3c",
        "0xB20df419576be6c3388D3c144A8dB63B284b11AA",
        "0x2a6320BC4AC217F4D59f828EDB5852D502Ff6bFA",
        "0x4435a6AAB0D0A1F1Bc72c3029fa58675fA337b6e",
        "0xB85b54286dF429DBc449f5Eea7fd44564c110eCD",
        "0xD02648c510582dc09D04cAaD507f4Bf3Bfd2bAbB",
        "0x37248CB67c4737eF8b29aaE2fe49396F3eafa1CF",
        "0x298a80Efb31b8892A9947724d6B3d6cFfd89c0fB",
        "0xce33e1821051619321c043f6dc98566416819ef5",
        "0x984Ac93277220CeAabdCBe8E36417268B0e62142",
        "0xe23CA2853A1809322b5950882a732110A6A3De28",
        "0x46F9dB961b0838C08E888306b833131A9f4E58Db",
        "0x4f85CcE632C291a4319137a14D4cd1Cc3436fBb0",
        "0x847c829d47596aFFbb5AA8c842b7E89c5294d6ea",
        "0x241eC344863577ddBe6808Be08E8dC44667B043B",
        "0xc292fF722230aA139bDf4e3d4422f04Ee09dA1d6",
        "0x3c8aDe5A04aB0e29720162B31C3eEf075B4e9cAf",
        "0x10FD4f518Be9dF8E599529D7726A97f70f2622Eb",
        "0x35A75f3A239308Ec6227604e3c7f888BC8D80e2C",
        "0xf247BB5d2bf002550fb79be876C7958D8F87F9EE",
        "0x7c0DC584Fc1045E1988D8Db3C82f363371a8dE0A",
        "0x2060da6F8c455a8D09Bb06940008844f7b81932f",
        "0xAAf3d2C7c6bB585c7C3C4d22d275CB8f3F95497f",
        "0xBE6fE941bB06D09EbC389ffF4836068f20832155",
        "0xf5b6a2a0d970471C4c2Dd7d6547a2f7d9A0904d8",
        "0x30c4BAed4fc913a6eeD8B682EF3c1d966898CB13",
        "0xcB92DE603414bD8Bc9382fe337207af6942Ce7a0",
        "0x1e8749D82E97DAE8a29C3F130198CA6bE619934d",
        "0xd49F61Cf2c6402409550fe16aB1731e84F2D7056",
        "0x6639b89655596711EAa97c9259616F52ab0442ad",
        "0xe4F0247B41ebdF86579BF9E51F371A3A943483D2",
        "0xAe00bCD0e6905F5B72f403aae9173ec93Ccd573d",
        "0x6c21F6bA98Acf3572Cb17Bb60a107f4f60Ea1CFe",
        "0x15CE7C9556FbD97a91472ab5796394Ad30eA7E8a",
        "0x4964a4DA52951748d0bB3E035856Ad2587FF1Ef5",
        "0xddd0aB40afe4D660d37201C596CF2d370CbED195",
        "0xc4A9C3f8Ef60BBe729579657b49c395Ac4E930d8",
        "0x34231dC53321f431F3e172273C4dEb90783bfCd3",
        "0xE9B765B2f5f82Ba82e08c27Ee9D3AF05975D5Ef8",
        "0x1EAa2dF8818663DcfF3D79166FdFcC92cFaC5e49",
        "0x1753a7E753E6547fE6590A549e938033190D2243",
        "0xA51ed625306DdbC3e4090CdCC909f74dFb3344c0",
        "0x2b90806bFF57625916A332046bAee1D12032ebBe",
        "0x78AF8Ea24462CBBA31AF57B68450264D8F804C31",
        "0x3E2c1E14059e1055D1C177Cf2dF3E88e0240283e",
        "0x159024c35cce29b73fCA3C47454cfF9742EC4C90",
        "0xA3567aC9374A1319A10BfB4b918776f477d82432",
        "0x764Cd3232C2e473530678890B380C206B93d1DcD",
        "0xeBa273aF3Ac00bAa2a3d0b773F15a3dF6B58D4B0",
        "0xbdf4f2984fbC99A67EDaF53502070bf907f797F8",
        "0x64fD0a38786D71F1A11643B27B830492424A42D6",
        "0xFEaB4E376Ad3d6d600b5839866f6e69fAbe4A8E5",
        "0xaa9272cbcc758e8b64678daff31a9ddcaf8dbaa8",
        "0x6397b7748B0f6F781cc8A5a3C6046DAC68351892",
        "0xF7bF43471D81d95c3056D76e5fc25e313401d068",
        "0xeD9383a171f6928528cB93abFa8Aeb3877A488eF",
        "0xF8Fa1662B67e6aB3F555946E23e359BC75209cB7",
        "0x12Fe6276bB85612F46B7b158f0040CBf62440141",
        "0xd613109cf4fda6A3604C948dB3ad9080de79A668",
        "0x28F363EeC6954C335f566DDD640e6CD791D5B649",
        "0xB0e94546F7f3440E0287Cc8CaBC5ADF381D02E3c",
        "0x90cad5dEb9F97d5D18478b97d5393963c16A95A7",
        "0xB20df419576be6c3388D3c144A8dB63B284b11AA",
        "0x9A5e8FB9c855EA23498ff75fCA035Bf7782272a0",
        "0x792aFB4087f072D5a2C259F92e8007777E52AEf5",
        "0xac850E7A2Bb3a54B7279745A3b07188f23afaC89",
        "0x78AF8Ea24462CBBA31AF57B68450264D8F804C31",
        "0xf95a456f8B2247B4135b0e0C752858eEa6E6f729",
        "0x2b90806bFF57625916A332046bAee1D12032ebBe",
        "0x78AF8Ea24462CBBA31AF57B68450264D8F804C31",
        "0x0863AAdAA1d818C9BFac5a10eAc5A323969E6c98",
        "0x867B903C642704E522d7400397E862E4B1153521",
        "0x021767232eA355e9e8461e7f006099e0A897da1f",
        "0x3010a2984bc18D2B12fa4E2C69A4D359EeA8C075",
        "0x2E07e267a129a771807a399666906dfF094F8Fa8",
        "0xA32Bb4A85BC2C09B77D452cF1D0Dff0a58F3117E",
        "0x3fe47025Ffa735C491A7b5A9D307D55706F81AEb",
        "0x9e668a92B532bf2b5FB7363EbCF835B80558b9DF",
        "0xE125714e00BaB0042A3C61e4d06B52E6e5038F98",
        "0xCad3bBA2661fEAd9E2C14Ed172ECB8Fab3373787",
        "0x77EC1Ca680eD5b30A955568e107B7a498C1dE33F",
        "0xfD4d0D3ED78cdC73ca5c124f6ed9ce1dC15D90e9",
        "0x570fC1C373e47983854Ad551b490De1EB8C654E7",
        "0x0863AAdAA1d818C9BFac5a10eAc5A323969E6c98",
        "0x20e275e1a16F88DB900927B9a12c74F9F90Dd6F6",
        "0xBCa1954a0E73Eea62BD488f9Be91cc9de69cc240",
        "0xb12e69cc43bb8b76302fa350a6ee9fcc018c7fa3",
        "0x22c0690586c0B8D2cF2a33D7e3B73326c8E613B8",
        "0x8724D539d1A60302cbbD2b447B750142d39CAd8E",
        "0x6820F1019445659F0c4B0Eb9E98DbA0F81A768cb",
        "0x7b0a7F015daD92907DEEf582093429B7Edf1e25C",
        "0x03E7C526d60288a0F56DBcd41B3351AE029860f7",
        "0x6A569d2Ff7e4c0A867358832a239aDC38aB53ef2",
        "0x58226E645665C849C751429EE0C24F2c3d1fc3FA",
        "0x2524C425c6D337746C939bcedfD5a3BD50f52E31",
        "0xFC054c00FF24D82492B793823Bdb33A17AfeC17c",
        "0x54aCEf6cAdcB8570FeCED9EA837C26Cd38f16815",
        "0xD36204cF9DbB6e79AEECFD0aE0184E346326166E",
        "0xA1A265dFeec4b95aD663e974f32C42099e399bD6",
        "0xA1A265dFeec4b95aD663e974f32C42099e399bD6",
        "0x5A749bB59b735622DB7C286f5E3D11325Fb0C56D",
        "0xc7442b2beebc604e3ab8894b9763616e052ca8ea",
        "0x146B6e239f2738fE36dbd7dC13A3994F377df3fC",
        "0x5867dB3C689a572595beBe50c40742a267b534d3",
        "0x05755A36B5Ea0cD7926Bc8E38b8dF8cB4cDF3249",
        "0x01c4BB3085821705Ff87FAF74e20688D5Dd07E26",
        "0x1c266303b98876558FBf5DE628282E22Ad736Ab9",
        "0x5b1d386109380Dc78018CE70b76D20EAAfB2880D",
        "0xB904F80bC9cC590E8D8935C760bfCb7Cfb2eA697",
        "0x0BD91D3FB125B87045c0f30F03Fc6617Fcc069f6",
        "0xD2A13584AA0E788DE9E5cd8856Ad1BdD2636CC19",
        "0x0D2428CE6dDa27D355da64C52b2e8BC53CCe0796",
        "0x8c0150272F1eB252Dd4cB31A1138Ca304cc0AA90",
        "0xc48A69860434f56ebd2A6B47Cc83Eb59cd5c1981",
        "0xb15886386795E0577F2B2590A8d722C512a905D5",
        "0xEDCff63F1713EB110105ef4DC49C6456BAe55c43",
        "0x345eBc07868498335bA9dd67485aBB346395D9a5",
        "0x667B4DbA9d57e2fC406738230AB4730Bc05E54df",
        "0x17146c47F9154BC0863556280bee6B5cf5e027a1",
        "0xc4cB4dd52AF3305ee7a7a08a4f807376F5cd1E07",
        "0xbdFfd0837D1b7cc3eEdB6302951a18D370feEff7",
        "0x5171a4a440e71a0A2d09d579D4aacc375De1C78E",
        "0xC76a1Ed4C5BAe61C89D8333BB33C76926Cb48D46",
        "0x260429d3419Cc3aCeb1Be3c1604477E700b755fa",
        "0x0c587e582856CdfdF9fe0b1cf8208CEF12D72148",
        "0x5aa230EBD0b234AC84547ca68D44e9203195332f",
        "0xC325ccA052e358be7d9B8dC546d164Ce51CB87E1",
        "0xe56D1058F59DD8AaBCbC8B2d7ceFF904607b79ba",
        "0xD2A13584AA0E788DE9E5cd8856Ad1BdD2636CC19",
        "0x51E958572cc09BDCA876C59B03B2833f3884dE67",
        "0xD3BFEbC39Ba55d4bC6a736B842b2e99D2AAAc73D",
        "0x23AdD72756d040F0027742c02947e072e0dd7a1e",
        "0xf580db43fD2D0ef9e530D477E722176d277e52E7",
        "0x40098760Fa011DbD7536298df45f146D7153C874",
        "0x5d971d71F9677166Cc3F5cE09897e00Dd81CFCf6",
        "0x07E49B6C3e6217c01eCffA4191beAA93bEa7Fce6",
        "0x4fA88500C0A2a7c56D2f19d91B609954c0629a14",
        "0xf5dd842EC95520A8101C897Ff5EB291dEF3Da261",
        "0x9D16f89A8949c40C84596c76fD3823F00d3b6e72",
        "0x06Ae83439935F8375c4F9f0340DC7F32E079458b",
        "0x57D5C3A2030F9C764BFcba7C7Abbbfc31294f653",
        "0xB7Df9F0a85f8583087f7b2d24755cdAD869364c7",
        "0x5d971d71F9677166Cc3F5cE09897e00Dd81CFCf6",
        "0x07E49B6C3e6217c01eCffA4191beAA93bEa7Fce6",
        "0x4fA88500C0A2a7c56D2f19d91B609954c0629a14",
        "0xf5dd842EC95520A8101C897Ff5EB291dEF3Da261",
        "0x9D16f89A8949c40C84596c76fD3823F00d3b6e72",
        "0x06Ae83439935F8375c4F9f0340DC7F32E079458b",
        "0x57D5C3A2030F9C764BFcba7C7Abbbfc31294f653",
        "0xB7Df9F0a85f8583087f7b2d24755cdAD869364c7",
        "0x22c9bBD38FfA2dc4C6AE3a08a99a20610d64d1fe",
        "0xf580db43fD2D0ef9e530D477E722176d277e52E7",
        "0xfc5f049c81f70926f0cff871d59463d074e173df",
        "0xEAa9dc5c179a40bde7Bda7e1b2542139E51C24e9",
        "0xc2df375fd5ea5d267a92ba26d3e07f7db0c6e45b",
        "0x1caca11d9849d4eed47e6188ab0495461b431c19",
        "0x14ed254ab9ed48cdd7e4a29218b676c746a5e68d",
        "0xebfa68ee799b59e8d275056d6cc65f7d5ca77210",
        "0x1caca11d9849d4eed47e6188ab0495461b431c19",
        "0x14ed254ab9ed48cdd7e4a29218b676c746a5e68d",
        "0xc2df375fd5ea5d267a92ba26d3e07f7db0c6e45b",
        "0x1d26f0310EcCB610ad1E8d71BB0CaBabF236692E",
        "0xfc5f049c81f70926f0cff871d59463d074e173df",
        "0xe3199bc59b7611297e855D5F03b63A71F92467Ac",
        "0x33829BE0c87E1CFa5f4BD4c384Ce1AbD477B4CbA",
        "0xb1C7E5cc46E43f403c12eb89277C9bd705EbF1cc",
        "0x776B75AFdd0B477336fF007016940F53af07A48F",
        "0x03B2B1dA1578996efb932C4e2D9AAA4bABed852b",
        "0x1B29C4583e5DC20c287F9C90A97e1A703D8e73f7",
        "0xFdB62D227cb4553D2F42a8C29421597998fE1Cc4",
        "0xb1070378891CC081C209B865C0d6930e674A7171",
        "0x5B048531A5D405191c0E837D0715364ab4449842",
        "0x1c266303b98876558FBf5DE628282E22Ad736Ab9",
        "0xb1070378891CC081C209B865C0d6930e674A7171",
        "0x5B048531A5D405191c0E837D0715364ab4449842",
        "0xCf072AA4dc6F4E976f6BF872a5e21eCbf95A7Ad2",
        "0xCf072AA4dc6F4E976f6BF872a5e21eCbf95A7Ad2",
        "0x662CF32102154f7FA11901e3AAd5c0FE52a50620",
        "0xDF71fcD1DE041E7Ab7938c69E02341b7E81fC278",
        "0xB8e240549A04467ea573d8E63e77cE3248D3362a",
        "0x4B3062c69cc1d778A8F9fCD57406fE9ab87AeB1F",
        "0x9445050610cc7A6e977A0b43c48F9c2682C7b351",
        "0xef019945F70478DB567c9985C1154408A0aDAae8",
        "0xCfDc416B8968eC8b571aDc23C090331E3D4FcAF8",
        "0x6927660DbDE05d4d6513111b3267614C7a567629",
        "0x1A69baED783938CC2F1Ec5B12748D11704f47dDF",
        "0xef547E0A6d402b617Ff4C9a8f7ccAebEDA407DED",
        "0xFa7E317274cd9E5B4e72aaff3a0Fc495604c8B99",
        "0x833431ce1E55086A377B8e471d1Bc190cD0cafD1",
        "0x4ba74257B9480F8f55330bcCad7F70d678BFCfBf",
        "0x4fbd4D72f7e5f814F0919b5df91a31205b421BD4",
        "0xe2A87ECe57a46c4f4ca7e62A4d31cA16A4f5E433",
        "0x7E0f9F067B25377ba78C1f1D7A27F8dECf923cab",
        "0x79Dd18A84af3742A5eFC8328610631fc67bda937",
        "0x3e3c8E6355da2Bde214B0332285F177458086565",
        "0x01c4BB3085821705Ff87FAF74e20688D5Dd07E26",
        "0x05755A36B5Ea0cD7926Bc8E38b8dF8cB4cDF3249",
        "0x43F9Fe3F17DD7a021A94149FdE57497c0E6292B5",
        "0xa21544ec7b78Cf281551fe3Af4ce9eb5784ff272",
        "0xf124ba6587D7ea2174bcC86eE5BC630b385F3a72",
        "0xcdf07ccb3d3ae7ead8355453f8c26f654c26e9f7",
        "0xee41d402D903C799B306Cf45569488D7765D00fA",
        "0x703772B0059FD463dAa79e144294515937Cc202f",
        "0x59bF34935f12b3f976A78FD31D57bB2f195332a7",
        "0x86Cc84356aBe85F4E07050DEBC65a371cdf64E6a",
        "0x5287DACF14f0518B7fD0B3E76dF5019F732C9848",
        "0x7CBF9B3519F3965bf8A5B98142049D66DBd8a2BE",
        "0x6A39A15960E6965E92b74B9Af53622D46B16fbB7",
        "0x656e9E26EAcB9A4fCCB45D40b9b2ba1aE2EF0f81",
        "0xc10B64139513bB4218B23a8D96a75057215ebf9B",
        "0x3184eA82686BdaB1439b76eeb7A302F6150e6Fac",
        "0x7B9cFcD89FD938CB4721CE3980E0F38b5cB3B365",
        "0x5d318D05f1d67b47D9a62aAfa068d94FD8190D28",
        "0x25b116685d46ac5125ed8b4757b5b1ad01b7cc7a",
        "0x005df898C5664be3026637f191d4482c6f8E9eE9",
        "0x7038bF1590048A183b08e922214CDE62977414be",
        "0x7038bF1590048A183b08e922214CDE62977414be",
        "0x4F1a86A1A19E93Fe3254E7407dAb6D2cBC0adc85",
        "0x8246d42467c88Af44cbB1c7d3344F64725a93F28",
        "0x49E615c4486787a348271da6F5aa1c84510EF82f",
        "0xE0C16543d9f7DF52B00474C53b1B2722f8389646",
        "0xAf6f87F1D3f9a723fB67956b630241f95CDF5f49",
        "0xA057558A56753577028f7039191C70522437C0bD",
        "0x83e7FF891B714df792a90c284108030fCaDd5E92",
        "0x17810527A88f79e9f96e1b5C96E8aE13be4B6F76",
        "0xb37D45c3C726B56FDeD07613A4D2bDF5d676CDaD",
        "0x0cA0939b4daDb19354467C41ce6d4aCD51178598",
        "0xC2de15b38D0D75debFE30c423FE960e6B117A305",
        "0x5Bc375169c28dc6d2e94c9f31f0e7D351F364832",
        "0xaD827791610832cCcb31FCD1085d6Da379dca32D",
        "0xCf072AA4dc6F4E976f6BF872a5e21eCbf95A7Ad2",
        "0x662CF32102154f7FA11901e3AAd5c0FE52a50620",
        "0xDF71fcD1DE041E7Ab7938c69E02341b7E81fC278",
        "0xB8e240549A04467ea573d8E63e77cE3248D3362a",
        "0x4B3062c69cc1d778A8F9fCD57406fE9ab87AeB1F",
        "0x0cA0939b4daDb19354467C41ce6d4aCD51178598",
        "0xC2de15b38D0D75debFE30c423FE960e6B117A305",
        "0x076dcfc02e31708e3d960a3875d0ca2b484eb0fa",
        "0xf4FcF3626324DD9c9dE871B3c5588408e0b3CCc9",
        "0x5Fbf4Ef00304046bD7A66A11FBBba567D81584c6",
        "0x4FcACc173cE09B2DFbC09aB42bAD6c8e7E283bC3",
        "0x5Fbf4Ef00304046bD7A66A11FBBba567D81584c6",
        "0x4fF29444b1ffe5f3301d65Af4cfF01a7C438051C"
      ]

      let isExclude = false;

      for(let i=0; i<arr.length; i++) {
        if(arr[i].toLowerCase() == useAppState.walletAddress.toLowerCase()) {
          isExclude = true;
        }
      }

      setData({
        vUsdt: useAppState.convert(_user.vUsdt),
        ROI: useAppState.convert(_user.ROI),
        directIncome: useAppState.convert(_user.directIncome),
        royaltyIncome: useAppState.convert(_user.royaltyIncome),
        uplineIncome: useAppState.convert(_user.uplineIncome),
        downlineIncome: useAppState.convert(_user.downlineIncome),
        generationIncome: useAppState.convert(_user.generationIncome),
        totalIncome: useAppState.convert(_user.totalIncome),
        amtInRTR: useAppState.convert(_amt),
        monthlyIncome: useAppState.convert(_user.monthlyIncome),
        curDayWithdraw: useAppState.convert(_dayWithdraw),
        isNew: (Number(_u.start) * 1000) < 1734769463682 && !isExclude ? false : true,
      })
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5 relative mb-4'>
          <img src='/bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-100' />
          <img src='/bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />

          <div className='flex flex-col md:flex-row w-full mt-3'>
              <div className='w-full md:w-3/5 mt-4 md:mt-0'>
                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="../tiffany-blur.webp" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.curDayWithdraw} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Day Withdrawal</span>
                    </p>
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.ROI} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>ROI Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.ROI} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.directIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Direct Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.directIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.uplineIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Upline Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.uplineIncome} USDT</span>
                      <span>Withdrawable</span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.downlineIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Downline Dividend Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.downlineIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.generationIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Generation Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.generationIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.royaltyIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Premium Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.royaltyIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>

                  <p className='relative bg-gray-700 bg-opacity-25'>
                    <img src="/pink-blur.png" className="absolute opacity-30 top-0 right-0 w-full h-full" />
                    <p className='flex flex-row-reverse justify-between items-end px-4 pb-3 pt-2 rounded-lg mt-4'>
                      <span className='text-lg font-semibold flex items-center'>{data.monthlyIncome} <img src="/usdt.png" className="h-4 ml-2" /></span>
                      <span>Royalty Income</span>
                    </p>
                    {/* <p className='flex flex-row-reverse justify-between text-sm text-[#72787d] items-end px-4 pb-2'>
                      <span className='text-sm font-semibold flex items-center'>{data.monthlyIncome} USDT</span>
                      <span>Withdrawable </span>
                    </p> */}
                  </p>
              </div>

            <div className="w-full ml-0 md:ml-6 md:w-2/5">
              <div className='w-full mt-8 md:mt-0'>
                <p className='flex justify-center items-center flex-col px-4 py-3 rounded-l2 w-full relative'>
                  <img src="/greenborder.png" className="absolute w-full top-0 left-0" />
                  <span className='text-sm font-semibold mt-3'><span className="text-green-500">Available</span> Withdrawl</span>
                  <span className="text-xl font-bold flex items-center">${data.totalIncome.toFixed(2)} <img src="/usdt.png" className="h-4 ml-2 mr-2" /> | {data.amtInRTR.toFixed(2)} <img src="/rtr.png" className="h-5 ml-2" /></span>
                </p>
                <div className='mt-12 w-full flex justify-center'>
                  <button className='px-14 py-4 text-sm font-semibold rounded-md renew-btn'>Cancel</button>
                  {/* <button onClick={data.isNew ? _withdraw : withdraw} className='ml-4 px-14 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={12} color="black" /> : 'Confirm'}</button> */}
                  <button onClick={() => message.warning("Work in Progress...")} className='ml-4 px-14 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={12} color="black" /> : 'Confirm'}</button>
                </div>
              </div>

              {/* Internal Transfer */}
              {/* <div className='w-full mt-8 relative pb-2'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 rounded-xl opacity-60' />
                <div className='flex justify-between items-center py-3 px-4 bg-gray-800 bg-opacity-25 rounded-t-xl'>
                  <p className='flex flex-col justify-center items-center'>
                    <span className='text-lg font-bold flex justify-center items-center'>${data.totalIncome.toFixed(2)} </span>
                    <span className=''>Wallet Bal</span>
                  </p>
                  <p className='flex flex-col justify-center items-center'>
                    <span className='text-lg font-bold flex justify-center items-center'>${data.vUsdt.toFixed(2)} </span>
                    <span className=''>VUSDT Bal</span>
                  </p>
                </div>              

                <div className='border border-[#76818b] border-opacity-50 w-full mt-8'></div>
                <p className='flex justify-between items-end px-2 md:px-4 py-3 rounded-lg mt-1'>
                  <input onChange={(e) => setTo(e.target.value)} value={to} className='text-lg w-full outline-none font-medium' style={{background: 'none'}} placeholder='Enter Receiver address' />
                </p>

                <div className='border border-[#76818b] border-opacity-50 w-full mt-2'></div>

                <p className='flex justify-between items-end px-2 md:px-4 py-3 rounded-lg mt-1'>
                  <input onChange={(e) => setAmount(e.target.value)} value={amount} className='text-lg outline-none font-medium' style={{background: 'none'}} placeholder='Enter amount in usdt' />
                </p>

                <div className='border border-[#76818b] border-opacity-50 w-full mt-2'></div>

                <div className='mt-6 w-full flex justify-center mb-5'>
                  <button onClick={transfer} className='px-8 md:px-16 py-4 text-sm font-semibold rounded-md renew-btn'>{loading2 ? <TailSpin height={15} color='white' /> : "Transfer"}</button>
                </div>
              </div> */}
            </div> 

            </div>

            {/* <div className='flex flex-col w-full mt-3'>
              <h1 className="mb-2 font-bold text-white"><span className="text-green-500">Transfer</span> History</h1>
              <TransferHistory />
            </div> */}

            {/* <div className='flex flex-col w-full mt-6'>
              <h1 className="mb-2 font-bold text-white"><span className="text-green-500">Withdrawal</span> History</h1>
              <WithdrawalHistory />
            </div> */}

        </div>
      </div>
    </div>
    </> 
  ) 
} 

export default Wallet
