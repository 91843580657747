import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect, createContext } from "react";
import { ethers } from "ethers";
import MGPDefi from '../src/artifacts/contracts/Rector2.sol/Rector2.json'
import BackupFunds from '../src/artifacts/contracts/BackupFunds.sol/BackupFunds.json'
import { Homepage, Dashboard, Deposit, Wallet, Teams, SplitAccount, Admin, TeamUsers } from "./components/index";
import { useTimer } from 'react-timer-hook';
import { Modal, Button } from "antd";

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

const Appstate = createContext();

function App() {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {ethereum} = window;
  const [walletAddress, setWalletAddress] = useState("");
  const [change, setChange] = useState(0);
  const [show, setShow] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAdmin2, setIsAdmin2] = useState(false);
  const [isAdmin3, setIsAdmin3] = useState(false);
  const [price, setPrice] = useState(0);
  const [isMd, setIsMd] = useState(false);
  
  const toggle = () => {
    setShow(!show);
  }

  useEffect(() => {
    const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
    if (isMdScreen) {
      setShow(true);
      setIsMd(true);
    }
  },[])

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };
  
  const usdt = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
  const rtr = "0x0863AAdAA1d818C9BFac5a10eAc5A323969E6c98";
  const oldrtr = "0x0FF15227EC8165B89365a1d3524E46C48BAD73FB";
  const mainAddr = "0xB9057c64Ff3f4C281E147d7eFF935d1A7eaCacb3";
  const backupFunds = "0x96aC4Bea6735BA5f7f58Ab20e4853683A84797C3";
  const feeAddress = "0x237a518Ff8b68a3446186957fe0C13f7bB7ea316";
  
  let provider;
  if(ethereum !== undefined) {
    provider = new ethers.providers.Web3Provider(window.ethereum);
  } else {
    provider = new ethers.providers.JsonRpcProvider();
  }

  const signer = provider.getSigner();
  const contract = new ethers.Contract(mainAddr, MGPDefi.abi, signer);
  const backup = new ethers.Contract(backupFunds, BackupFunds.abi, signer);
  const tokenContract = new ethers.Contract(usdt, erc20Abi, signer);
  const tokenContract2 = new ethers.Contract(rtr, erc20Abi, signer);
  const tokenContract3 = new ethers.Contract(oldrtr, erc20Abi, signer);

  useEffect(() => {
    async function req() {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setWalletAddress(accounts[0]);
      // if(accounts[0].toLowerCase() == ("0xF2C6378A6E9aC3676156128969DFb15E23e2Fcd5").toLowerCase()) {
        setIsAdmin(true);
      // }
      // setWalletAddress("0x4641E0bD51Eae0C79ebB5AbC815c47D40f9142E7");
      // setWalletAddress("0x36BCBfDe04ce5ea8f6489B15B1D6500Ff8aD8F65");
      // setWalletAddress("0x00A7Ea00db4525035f7A83607b6b06CFf537c580");
      // setWalletAddress("0xbc5af6f4b75f3f19f495133a31dc103b8d6d30e4");
      // setWalletAddress("0x120DC3412b1B4a83fe2c21DB5eCD8E1D3cA02A84");
      let _price = await contract.getQuoteInUSDT(ethers.utils.parseUnits("1", "mwei"));
      setPrice(convert(_price));
    }
    req();
  }, []);

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  const convert = (val) => {
    return Number(ethers.utils.formatUnits(val, "mwei"));
  }

  let lvlName = ["NEWBIE", "SILVER" , "GOLD", "PLATINIUM" ,"EMERALD", "RUBY", "DIAMOND", "DOUBLE-DIAMOND"];

  return (
    <Appstate.Provider value={{mainAddr, contract,tokenContract,tokenContract3, backup, backupFunds, price, lvlName, tokenContract2, isAdmin, isAdmin2, isAdmin3, provider ,convert ,formatTime ,walletAddress,setWalletAddress ,setChange, change, toggle, show, setShow, isMd}}>
      <Modal
        title={
          <div className="text-2xl font-semibold text-red-600">
            "Unlock Exclusive Benefits with Rector’s Latest Offer!"
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <Button
            key="close"
            onClick={handleOk}
            className="bg-blue-600 text-white hover:bg-blue-500"
          >
            Close
          </Button>,
        ]}
        width={600}
      >
        <div className="p-2 text-gray-700">
          <p className="mb-4 text-lg">

          </p>

          <div className="bg-yellow-100 p-4 rounded-md border-l-4 border-yellow-500">
            <img src="offer.jpg" />
          </div>

          <p className="mt-4 text-gray-700">
          “Thank you for being a valued member of the Rector community. Your trust and support inspire us to keep improving and delivering the best experience possible.”
          </p>
        </div>
      </Modal>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/teamusers" element={<TeamUsers />} />
        <Route path="/split" element={<SplitAccount />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/manage" element={<Admin />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Appstate.Provider>
  );
}

export default App;
export {Appstate};
